/* eslint-disable max-statements */
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import CLOCKIMG from "./images/clock.svg";
// import LOCATIONICON from "./images/location.svg";
// import DOCUMENT from "./images/document.svg";
import Button from "../../shared/button";
// import CustomerCareSupport from "../customer-care-support";
import  AfterTestDrive from "../../shared/after-test-drive";
import ArrowIcon from "../../shared/arrow";
import { getCarImage } from "../../../utils/helpers/split-payment";
import { useHistory } from "react-router";
import CallIcon from "./images/calling.svg";
import CarImage from "./images/car.png";
import { getTradePriceLabel } from "../../../utils/helpers/calculate-egc-dap";
// import useTimer from "../../../hooks/use-timer";
import { ORDER_TYPE } from "../../../constants/app-constants";
import { getCheckoutPageURL } from "../../../utils/helpers/get-detail-page-url";
import { checkoutRoutes } from "../../../constants/checkout-routes";
// import { formatDeliveryAddressToString } from "../../../utils/helpers/format-address-object";
import TestDrivePaymentReciept from "../test-drive-payment-reciept";
import useCountDown from "../../../hooks/use-countdown";
import Loader from "../../shared/loader";
import navigateToMaps from "../../../utils/helpers/navigate-to-maps";
import { poll } from "../../../utils/polling";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";
import { trackMobileCustomEventsAU } from "../../../tracking";
import ReservationConfirmTestDrive from "../../shared/reservation-confirm-test-drive";
import { TEST_DRIVE_MANUAL } from "../../../constants/checkout-constants";

const TestDriveFinishPage = ({
    match: {
        params: { carId}
    },
    config = {},
    content = {},
    egcData = [],
    order,
    secureToken,
    chargeDetails,
    bookingSummary,
    getOrderConnect,
    fetchCarDetailsConnect,
    getBookingSummaryConnect,
    fetchEgcConnect,
    updateOrderConnect,
    isUserZeroDpVariant
}) => {
    const history = useHistory();
    const [showOrderSummary, setShowOrderSummary] = useState(false);
    const { orderId} = order || {};
    const {appointmentId, stateCode} = content;
    const {imageUrl, carFullName} = getCarImage({config, content});
    const {testDriveDetails, referrerSource, paymentMethodType} = bookingSummary || {};
    const {testDriveExpiry = "", yardAddress = {}, isTestDriveBookingUnderBusinessHours} = testDriveDetails || {};

    useEffect(() => {
        fetchCarDetailsConnect(carId);
        getOrderConnect(carId);
    }, [carId, fetchCarDetailsConnect, getOrderConnect]);

    const isBookingSummaryAvailable = (result) => {
        if (Object.keys(result.data) && result.data.testDriveDetails) {
            return true;
        }
        return false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const pollBookingSummary = async () => {
        try {
            const params = {
                fn: getBookingSummaryConnect,
                validate: isBookingSummaryAvailable,
                interval: 1000,
                maxAttempts: 5,
                fnParams: appointmentId
            };
            await poll(params);
        } catch (error) {
            // history.replace(window.location.pathname);
        }
    };

    useEffect(() => {
        if (secureToken && appointmentId) {
            pollBookingSummary(appointmentId);
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.CHECKOUT_PAGE, {eventAction: "confirm_screen", eventLabel: "car_reserved"});

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId, secureToken]);

    useEffect(() => {
        if (appointmentId) {
            const code = stateCode.split("_");
            fetchEgcConnect(appointmentId, code[1]);
        }
    }, [appointmentId, fetchEgcConnect, stateCode]);

    const handleBack = () => {
        // trackEvent("MY_BOOKING_TASKS", "pay_now");
        history.push("/my-bookings");
    };

    const trackEvent = (eventLabel) => {
        trackMobileCustomEventsAU(AU_MOBILE_EVENTS.ORDER_SUMMARY_POST_PAYMENT, {eventLabel});
    };

    const handleSummaryModal = () => {
        trackEvent("view_receipt");
        setShowOrderSummary(!showOrderSummary);
    };

    const handleBuyNow = () => {
        const payload = {
            orderType: ORDER_TYPE.BUY_ONLINE
        };
        updateOrderConnect(orderId, payload);
        trackEvent("buy_car");
        const {relativeURL} = getCheckoutPageURL(content, checkoutRoutes.basicDetails.route);
        history.push(relativeURL);
    };

    const {totalDap} = getTradePriceLabel({egcData, chargeDetails, isUserZeroDpVariant });

    // const { seconds, minutes, hours, days } = useTimer({expireTime: testDriveExpiry});
    const [days, hours, minutes, seconds] = useCountDown(testDriveExpiry);

    const handleNavigateMap = () => {
        trackEvent("map_link_clicked");
        navigateToMaps(yardAddress);
    };

    if (!testDriveExpiry) {
        return <Loader/>;
    }

    return (
        <div>

            <div styleName={"styles.modalWrapper"}>
                <div styleName={"styles.modalHeader"}>
                    <span styleName={"styles.backIcon"} onClick={handleBack}><ArrowIcon black={"BlackArrow"} rotateBy={90} /></span>
                My bookings
                    <span styleName={"styles.callIcon"}><img src={CallIcon} alt="call" /></span>
                </div>
                <div styleName={"styles.carSummary"}>
                    <div styleName={"styles.thumbnail"}>
                        <div styleName={"styles.carImage"}><img src={CarImage || imageUrl} alt="car" /></div>
                        {totalDap.label ? <span styleName={"styles.carPrice"}>{totalDap.label}</span> :
                            <React.Fragment>
                                <span className="shimmer" styleName={"styles.shimmerCarPrice"}>&nbsp;</span>
                            </React.Fragment>
                        }
                        <h2 styleName={"styles.carName"}>{carFullName}</h2>
                        {(referrerSource === TEST_DRIVE_MANUAL && paymentMethodType === TEST_DRIVE_MANUAL) && <span styleName={"styles.viewOrderSummaryCta"} onClick={handleSummaryModal}>VIEW PAYMENT RECEIPT</span>}
                    </div>
                    <div styleName={"styles.deliveryDate"}>
                        <span>Booking ID:</span>
                        <span>{orderId}</span>
                        {/* <span className="shimmer" styleName={"styles.shimmerDate"}>&nbsp;</span> */}
                        {/* <span className="shimmer" styleName={"styles.shimmerDate"}>&nbsp;</span> */}
                    </div>
                </div>
            </div>

            <div styleName={"styles.first-section"}>
                <img src={CLOCKIMG} alt="" />
                <div styleName={"styles.border"}>
                    <p styleName={"styles.heading"}>Car is reserved for you for the next</p>
                    <p styleName={"styles.day"}>{days} Days<strong styleName={"styles.time"}>{hours}:{minutes}:{seconds} </strong> <span>Hrs</span></p>
                </div>
            </div>
            {/* <div styleName={"styles.sec-section"}>
                <img src={LOCATIONICON} alt="" />
                <div styleName={"styles.test"}>
                    <h4 styleName={"styles.test-text"}>Test drive location</h4>
                    <a target="_blank" onClick={handleNavigateMap} styleName={"styles.location"}>
                        {formatDeliveryAddressToString(yardAddress).addressArray.map(item =>
                            <span key={item}>{item}<br/></span>
                        )}
                    </a>
                </div>
                // formatDeliveryAddressToString(yardAddress)
            </div>
            <CustomerCareSupport isTestDriveBookingUnderBusinessHours={isTestDriveBookingUnderBusinessHours} /> */}
            {/* <div styleName={"styles.third-section"}>
                <div styleName={"styles.document-section"}>
                    <img src={DOCUMENT} alt="" />
                    <p styleName={"styles.document"}>Documents Required</p>
                </div>
                <p styleName={"styles.following-document"}>You will need the following documents to test drive your car:</p>
                <p styleName={"styles.point"}>1. Valid and in-date driving license</p>
            </div> */}
            <ReservationConfirmTestDrive
                handleNavigateMap={handleNavigateMap}
                yardAddress={yardAddress}
                isTestDriveBookingUnderBusinessHours={isTestDriveBookingUnderBusinessHours}
            />
            <AfterTestDrive/>
            <div styleName={"styles.last-section"}>
                <h4 styleName={"styles.new-car-text"}>Can’t wait for your new car?</h4>
                <p styleName={"styles.para-text"}>But it now and test own it for 7 days after you’ve paid the full amount.</p>
            </div>
            <div styleName={"styles.buttonWrapper"}>
                <Button text="BUY CAR" onClick={handleBuyNow} />
            </div>
            {showOrderSummary && <TestDrivePaymentReciept  onClose={handleSummaryModal} />}
        </div>
    );
};

TestDriveFinishPage.propTypes = {
    content: PropTypes.object,
    config: PropTypes.object,
    egcData: PropTypes.array,
    isUserZeroDpVariant: PropTypes.bool,
    chargeDetails: PropTypes.array,
    getOrderConnect: PropTypes.func,
    match: PropTypes.object,
    order: PropTypes.object,
    getBookingSummaryConnect: PropTypes.func,
    fetchCarDetailsConnect: PropTypes.func,
    fetchEgcConnect: PropTypes.func,
    bookingSummary: PropTypes.object,
    createOrderConnect: PropTypes.func,
    updateOrderConnect: PropTypes.func,
    secureToken: PropTypes.string
};

export default TestDriveFinishPage;
