import React from "react";
import PropTypes from "prop-types";

import Modal from "../../shared/modal";
import PaymentCard from "../payment-card/component";
import styles from "./styles.css";
import CloseButton from "../../shared/icon-cross";
import { PAYMENT_STATUS } from "../../../constants/checkout-constants";

const TestDrivePaymentReciept = ({onClose, bookingSummary = []}) => {
    const { orderPaymentDetails = [] } = bookingSummary || {};
    const payHistory = (orderPaymentDetails || []).filter(item => item.status === PAYMENT_STATUS.SUCCESS);

    return (
        <Modal isOpen={true}>
            <div styleName={"styles.wrapper"}>
                <div styleName={"styles.header"}>
                    <h4 styleName={"styles.heading"}>PAYMENT RECEIPT</h4>
                    <div styleName={"styles.headerActions"}>
                        <span>
                            <CloseButton type="grey" onClickHandler={onClose} />
                        </span>
                    </div>
                </div>
                <div>
                    {payHistory.map((item, index) =>
                        <PaymentCard key={index} {...item} label={index ? `Payment ${index}` : "Deposit"}/>
                    )}
                </div>

            </div>
        </Modal>
    );
};

TestDrivePaymentReciept.propTypes = {
    onClose: PropTypes.func,
    bookingSummary: PropTypes.array
};
export default TestDrivePaymentReciept;
