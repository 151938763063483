import React from "react";
import PropTypes from "prop-types";

import styles from "./styles.css";
import ErrorIcon from "./images/error.svg";
// import Driva from "./images/driva.png";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import { getBookingConfirmedDate } from "../../../utils/helpers/get-day-from-date";
// import Watch from "./images/watch.svg";
// import WatchOrange from "./images/watch-orange.svg";
import Verified from "./images/verified.svg";
import { PAYMENT_STATUS } from "../../../constants/checkout-constants";
import { getPaymentMethodType } from "../../../utils/helpers/checkout-util";

const ICON = {
    SUCCESS: Verified,
    FAILED: ErrorIcon
};

const PaymentCard = ({paymentIdentifier, updateAt, amount, status, label, paymentGateway}) => {

    return (
        <React.Fragment>
            {status !== PAYMENT_STATUS.CREATED &&
                <div styleName={"styles.outer"}>
                    <div styleName={"styles.headerWrap"}>
                        <p styleName={"styles.payment"}><img src={ICON[status]} />{label}</p>
                        <p styleName={"styles.amount"}>{makePriceLabelRound(amount)}</p>
                    </div>
                    <div styleName="bodyWrap">
                        <p styleName={"styles.taxID"}>Txn. ID: #{paymentIdentifier}</p>
                        <p styleName={"styles.date"}>On {getBookingConfirmedDate(new Date(updateAt))}</p>
                        <p styleName={"styles.netBanking"}>Paid with {getPaymentMethodType(paymentGateway)}</p>
                        {status === PAYMENT_STATUS.FAILED &&
                        <React.Fragment>
                            <p styleName={"styles.paymentFailed"}>Payment failed</p>
                            <p styleName={"styles.conclusion"}>Any amount deducted will be refunded within 3-5 business days</p>
                        </React.Fragment>
                        }
                        {/* <p styleName={"styles.processing"}>Payment processing by partner bank</p>
                    <p styleName={"styles.loadPartner"}>Payment processing by loan partner</p>
                    <img src={Driva} />
                    <p styleName={"styles.nationalBank"}>National Australian bank</p>
                    <p styleName={"styles.conclusion"}>Any amount deducted will be refunded within 3-5 business days</p> */}
                    </div>
                </div>
            }
        </React.Fragment>
    );
};

PaymentCard.propTypes = {
    paymentIdentifier: PropTypes.string,
    updateAt: PropTypes.string,
    amount: PropTypes.number,
    status: PropTypes.string,
    label: PropTypes.string,
    paymentGateway: PropTypes.string
};
export default PaymentCard;
